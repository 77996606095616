import { useCallback } from "react";
import { useSnackbar } from "notistack";

import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import CommentIcon from "@mui/icons-material/Comment";

import {
  getAuthenticatedUser,
  getEntities,
  getImageUrl,
  changePasswordUser,
} from "../../../api/v1";
import { CATEGORY_TYPE, STATUS_ENTITY } from "../../../constants";
import { get } from "lodash";
import { isEmpty } from "lodash";

const PAGE_SIZE = 12;
export const TYPES = {
  GET_USER: "GET_USER",
  LOGOUT_USER: "LOGOUT_USER",
  GET_CONFIGS: "GET_CONFIGS",
  BUILD_MENU: "BUILD_MENU",
  SET_BACKGROUND: "SET_BACKGROUND",
  TOGGLE_MENU: "TOGGLE_MENU",
  OPEN_MENU: "OPEN_MENU",
  SET_CURRENT_NODE: "SET_CURRENT_NODE",
  SET_LATEST_NODE: "SET_LATEST_NODE",
  UPDATE_MENU: "UPDATE_MENU",
  SHOW_SETTING: "SHOW_SETTING",
  CHANGE_PLACEMENT: "CHANGE_PLACEMENT",
  SAVE_SETTING: "SAVE_SETTING",
  FETCHING_MENU: "FETCHING_MENU",
};

export function useActionCreator(dispatch) {
  const { enqueueSnackbar } = useSnackbar();

  const onGetAuthenticatedUser = useCallback(
    (username, password) =>
      getAuthenticatedUser({ username, password })
        .then((user) => {
          enqueueSnackbar("Đăng nhập thành công", { variant: "success" });
          return dispatch({
            type: TYPES.GET_USER,
            user,
          });
        })
        .catch((err) => {
          console.log("aaa that bai");
          enqueueSnackbar("Đăng nhập thất bại", { variant: "error" });
        }),
    [dispatch]
  );

  const onGetConfigs = useCallback(
    () =>
      getEntities("system-configurations", {
        sort: "id,desc",
        size: 1000,
      }).then(({ items }) => {
        return dispatch({
          type: TYPES.GET_CONFIGS,
          configs: items,
        });
      }),
    [dispatch]
  );

  const onSetBackground = useCallback(
    (background) => {
      return dispatch({
        type: TYPES.SET_BACKGROUND,
        background,
      });
    },
    [dispatch]
  );

  const onToggleMenu = useCallback(
    (open) =>
      dispatch({
        type: TYPES.TOGGLE_MENU,
      }),
    [dispatch]
  );

  const onOpenMenu = useCallback(
    (open) =>
      dispatch({
        type: TYPES.OPEN_MENU,
        open,
      }),
    [dispatch]
  );

  const onShowSetting = useCallback(
    (showSetting) =>
      dispatch({
        type: TYPES.SHOW_SETTING,
        showSetting,
      }),
    [dispatch]
  );

  const onSaveSetting = useCallback(() => {
    dispatch({
      type: TYPES.SAVE_SETTING,
    });
    return Promise.resolve();
  }, [dispatch]);

  const onChangePlacement = useCallback(
    (menuPlacement) =>
      dispatch({
        type: TYPES.CHANGE_PLACEMENT,
        menuPlacement,
      }),
    [dispatch]
  );

  const onSetCurrentNode = useCallback(
    (currentNode) =>
      dispatch({
        type: TYPES.SET_CURRENT_NODE,
        currentNode,
      }),
    [dispatch]
  );

  const onSetLatestNode = useCallback(
    (latestNode) =>
      dispatch({
        type: TYPES.SET_LATEST_NODE,
        latestNode,
      }),
    [dispatch]
  );

  const onLogoutUser = useCallback(() => {
    window.localStorage.removeItem("id_token");
    dispatch({
      type: TYPES.LOGOUT_USER,
    });
    enqueueSnackbar("Đăng xuất thành công", { variant: "success" });
    return Promise.resolve();
  }, [dispatch]);

  const onFetchingMenu = useCallback(
    (fetching) => {
      return dispatch({
        type: TYPES.FETCHING_MENU,
        fetching,
      });
    },
    [dispatch]
  );

  const onChangePasswordUser = useCallback(
    (payload) =>
      changePasswordUser(payload).then(() => {
        enqueueSnackbar("Đổi mật khẩu thành công", { variant: "success" });
        return Promise.resolve();
      }),
    [dispatch]
  );

  const onUpdateMenu = useCallback(
    async ({ api, childrenApi, id, key, page }) => {
      onFetchingMenu(true);
      const { items, totalCount } = await api({
        ...(id && { id }),
        ...(page && { page }),
      });
      let children = [];
      if (items?.length > 0) {
        children = items.map((item, index) => {
          let photo = item.image;
          try {
            photo = JSON.parse(photo).original || JSON.parse(photo)[0].original;
          } catch (err) {
            photo = {};
          }
          return {
            ...item,
            label: item?.name,
            level: 2,
            background: getImageUrl({ path: photo }),
            key: `${key}.${index}`,
            ...(childrenApi && {
              api: childrenApi,
              id: item.id,
              children: [],
            }),
            ...(!childrenApi && {
              link: `/${key.split(".")[0]}/detail/${get(
                item,
                "configuration.friendlyName"
              )}-${item.id}`,
            }),
          };
        });
      }
      return dispatch({
        type: TYPES.UPDATE_MENU,
        children,
        key,
        hasPaging: totalCount > PAGE_SIZE,
        count: Math.ceil(totalCount / PAGE_SIZE),
        page,
      });
    },
    [dispatch]
  );

  const onBuildMenu = useCallback(
    async (configs) => {
      const menu = [
        {
          key: "services",
          level: 1,
          label: "Dịch vụ",
          children: [],
          background: getImageUrl({
            path: get(configs, `service_background.value[0].original`),
          }),
          api: () =>
            getEntities("categories", {
              sort: "id,desc",
              "type.equals": CATEGORY_TYPE.SERVICE,
            }),
          childrenApi: ({ id }) =>
            getEntities("services", {
              sort: ["order,asc", "id,desc"],
              "categoryId.equals": id,
            }),
        },
        {
          key: "teams",
          level: 1,
          label: "Đội ngũ SSD",
          background: getImageUrl({
            path: get(configs, `team_background.value[0].original`),
          }),
          api: () =>
            getEntities("teams", {
              size: 1000,
              sort: ["order,asc", "id,desc"],
            }),
          children: [],
        },
        {
          key: "galleries",
          level: 1,
          label: "Thư viện ảnh",
          background: getImageUrl({
            path: get(configs, `gallery_background.value[0].original`),
          }),
          children: [],
          api: () =>
            getEntities("albums", {
              size: PAGE_SIZE,
              sort: ["order,asc", "id,desc"],
            }),
        },
        {
          key: "articles",
          level: 1,
          label: "Kiến thức nha khoa",
          page: 0,
          background: getImageUrl({
            path: get(configs, `article_background.value[0].original`),
          }),
          children: [],
          api: ({ page }) =>
            getEntities("posts", {
              page,
              size: PAGE_SIZE,
              sort: ["order,asc", "id,desc"],
            }),
        },
        {
          key: "contactForm",
          level: 1,
          label: "Đặt hẹn khám",
          background: getImageUrl({
            path: get(
              configs,
              `contact_consulting_background.value[0].original`
            ),
          }),
          link: "/contact-consulting",
        },
        {
          key: "contact",
          level: 1,
          label: "Liên hệ",
          background: getImageUrl({
            path: get(configs, `contact_background.value[0].original`),
          }),
          children: [
            {
              key: "phone",
              level: 2,
              label: "Hotline",
              background: getImageUrl({
                path: get(configs, `contact_background.value[0].original`),
              }),
              subTitle: get(configs, `contact_hotline.value`),
              icon: <PhoneInTalkIcon sx={{ fontSize: "15px" }} />,
              action: () =>
                window.open(
                  `tel:${get(configs, `contact_hotline.value`)}`,
                  "_self"
                ),
            },
            {
              key: "zalo",
              level: 2,
              label: "Zalo",
              icon: <CommentIcon sx={{ fontSize: "15px" }} />,
              background: getImageUrl({
                path: get(configs, `contact_background.value[0].original`),
              }),
              subTitle: get(configs, `contact_zalo.value`),
              action: () =>
                window.open(
                  `https://zalo.me/${get(configs, `contact_zalo.value`)}`,
                  "_blank"
                ),
            },
            {
              key: "facebook",
              level: 2,
              label: "Facebook",
              background: getImageUrl({
                path: get(configs, `contact_background.value[0].original`),
              }),
              subTitle: get(configs, `contact_facebook_name.value`),
              icon: <FacebookIcon sx={{ fontSize: "15px" }} />,
              action: () =>
                window.open(
                  `https://www.facebook.com/${get(
                    configs,
                    `contact_facebook_id.value`
                  )}`,
                  "_blank"
                ),
            },
            ...[
              get(configs, `contact_email_1.value`),
              get(configs, `contact_email_2.value`),
              get(configs, `contact_email_3.value`),
            ]
              .filter((value) => !isEmpty(value?.trim()))
              .map((value, index) => ({
                key: `email_${index}`,
                level: 2,
                label: "Email",
                background: getImageUrl({
                  path: get(configs, `contact_background.value[0].original`),
                }),
                icon: <EmailIcon sx={{ fontSize: "15px" }} />,
                subTitle: value,
                action: () => {
                  window.location.href = `mailto:${value}`;
                },
              })),
            {
              key: "info",
              level: 2,
              label: "Thông tin",
              background: getImageUrl({
                path: get(configs, `contact_background.value[0].original`),
              }),
              link: "/contact",
            },
          ],
        },
      ];

      return dispatch({
        type: TYPES.BUILD_MENU,
        menu,
      });
    },
    [dispatch]
  );
  return {
    onSetCurrentNode,
    onSetLatestNode,
    onOpenMenu,
    onToggleMenu,
    onSetBackground,
    onBuildMenu,
    onGetAuthenticatedUser,
    onLogoutUser,
    onGetConfigs,
    onUpdateMenu,
    onShowSetting,
    onChangePlacement,
    onSaveSetting,
    onFetchingMenu,
    onChangePasswordUser,
  };
}
