import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import LogoWhite from './logo.svg';
import LogoDark from './logo_dark.svg';

import style from './style.module.scss';
import classNames from 'classnames';
import useResponsive from '../hooks/useResponsive';
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({
  disabledLink = false,
  sx,
  isCollapse,
  color = 'white',
  withLabel = true,
}) {
  const isMobile = useResponsive('down', 'md');
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }} className={style.logo}>
      {color === 'white' ? (
        <img
          src={LogoWhite}
          alt="logo"
          className={classNames(style.logoWhite, {
            [style.isMobile]:
              navigator.userAgent.indexOf('Mobile') !== -1 ||
              navigator.userAgent.indexOf('iPhone') !== -1 ||
              navigator.userAgent.indexOf('Android') !== -1 ||
              navigator.userAgent.indexOf('Windows Phone') !== -1,
          })}
        />
      ) : (
        <img src={LogoDark} alt="logo" />
      )}
      {/* {withLabel && (
        <div
          className={classNames(style.companyName, {
            [style.hidden]: isCollapse,
          })}
        >
          Nha Khoa SSD
        </div>
      )} */}
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <div className={style.logoWrapper}>
      <RouterLink to="/">{logo}</RouterLink>
    </div>
  );
}
