// routes
import Router from './routes/index';
import ThemeProvider from './theme';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';

export default function App() {
  return (
    <ThemeProvider>
      <NotistackProvider>
        <ProgressBarStyle />
        <Router />
      </NotistackProvider>
    </ThemeProvider>
  );
}
