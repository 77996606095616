import { intersection, isEmpty } from 'lodash';
import { ROLES } from '../constants';

export const setUser = (data) => {
  localStorage.setItem('user', JSON.stringify(data));
};

export const getUser = () => {
  let user = localStorage.getItem('user');
  if (user === null) {
    user = JSON.parse(user);
  }
  return user;
};

export const canRender = (roles, acceptedRoles) => {
  const intersectionRoles = intersection(roles, acceptedRoles);
  if (
    roles?.includes(ROLES.ADMIN) ||
    intersectionRoles?.includes(ROLES.ADMIN)
  ) {
    return true;
  }
  return intersectionRoles?.length > 0;
};

export const getFullName = (user) => {
  return [user.firstName, user.lastName]
    .filter((value) => !isEmpty(value))
    .join(' ');
};
