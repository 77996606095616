import { Navigate, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import { useDashboardContext } from '../container/context';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const ProjectedRoute = ({ children }) => {
  const params = queryString.parse(window.location.search);
  const navigate = useNavigate();
  const { user, onGetAuthenticatedUser } = useDashboardContext();
  useEffect(() => {
    if (!user.login) {
      onGetAuthenticatedUser().catch((err) => {
        navigate('/user/login');
      });
    }
  }, []);

  return children;
};

export default ProjectedRoute;
