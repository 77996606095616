import { replace } from "lodash";
import { makeHttpClient } from "./factory";

const ApiPath = "https://nhakhoassd.com/api/";
const { getJSON, deleteJSON, putJSON, postJSON } = makeHttpClient({
  baseUrl: ApiPath,
  withCredentials: true,
});

export const getAuthenticatedUser = () => getJSON("/account");

export const changePasswordUser = (payload) =>
  postJSON("/account/change-password", payload);

export const authenticate = (user) => postJSON("authenticate", user);

export const getConfigs = (params) => getJSON("/system-configurations", params);

export const getConfig = (id) => getJSON(`/system-configurations/${id}`);

export const getEntity = (entity, id) => getJSON(`/${entity}/${id}`);

export const getEntities = (entity, params) => getJSON(`/${entity}`, params);

export const updateEntity = (entity, id, payload) =>
  putJSON(`/${entity}/${id}`, payload, { message: "Update Successfully!" });

export const saveNewEntity = (entity, payload) =>
  postJSON(`/${entity}`, payload);

export const deleteEntity = (entity, id) => deleteJSON(`/${entity}/${id}`);

export const saveImage = (data) =>
  postJSON("/files/save", data, {
    transformResponse: [],
    transformRequest: [],
  });

export const deleteImage = (path) => deleteJSON(`/files/delete?path=${path}`);

export const getImage = ({ path }) =>
  fetch(`${ApiPath}files?path=${path}`)
    .then((data) => data.blob())
    .then((img) => {
      const src = URL.createObjectURL(img);
      return src;
    });

export const getImageUrl = ({ path }) => `${ApiPath}files?path=${path}`;

export const getImagePathFromUrl = (url) =>
  replace(url, `${ApiPath}files?path=`, "");

export const sentContactMail = (payload) => postJSON(`/email/`, payload);
