import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  MenuItem,
  Avatar,
  Button,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
// components
import MenuPopover from "../../../components/MenuPopover";
import { IconButtonAnimate } from "../../../components/animate";
import { useDashboardContext } from "../../../layouts/container/context";
import { HOME_PAGE } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { getFullName } from "../../../utils";

export default function AccountPopover() {
  const navigate = useNavigate();
  const { user, onLogoutUser, onChangePasswordUser } = useDashboardContext();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () =>
    onLogoutUser().then(() => {
      navigate("../user/login");
    });

  const handleLogin = () => {
    navigate("../user/login");
  };

  const handleHome = () => {
    navigate("../home");
  };

  const handleChangePassword = () => {
    navigate("../user/change-password");
  };

  return user.login ? (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar alt={`${getFullName(user)}`} sx={{ width: 24, height: 24 }} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {`${getFullName(user)}`}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {`${user.email}`}
          </Typography>
        </Box>
        <MenuItem sx={{ m: 1 }} onClick={handleChangePassword}>
          Change password
        </MenuItem>
        <MenuItem sx={{ m: 1 }} onClick={handleHome}>
          Go to User page
        </MenuItem>
        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem sx={{ m: 1 }} onClick={handleLogout}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  ) : (
    <>
      <Button variant="contained" onClick={handleLogin} endIcon={<LoginIcon />}>
        Login
      </Button>
    </>
  );
}
