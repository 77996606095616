export const INPUT_TYPE = {
  STRING: 'STRING',
  OPTION: 'OPTION',
  NUMBER: 'NUMBER',
  RADIO: 'RADIO',
  TAGS: 'TAGS',
  EDITOR: 'EDITOR',
  CHECKBOX: 'CHECKBOX',
  SWITCH: 'SWITCH',
  IMAGE: 'IMAGE',
};

export const DATA_TYPE = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
};

export const DEFAULT_VALUE = {
  STRING: '',
  OPTION: [],
  NUMBER: 0,
  RADIO: null,
  TAGS: [],
  EDITOR: '',
  CHECKBOX: true,
  SWITCH: true,
  IMAGE: null,
};

export const HOME_PAGE = '/dashboard/home';
export const PAGE_SIZE = 25;

export const ROLES = {
  USER: 'ROLE_USER',
  ADMIN: 'ROLE_ADMIN',
  EDITOR: 'ROLE_EDITOR',
};

export const STATUS_ENTITY = {
  SHOW: 'Show',
  HIDE: 'Hide',
  PUBLISH: 'Publish',
  DRAFT: 'Draft',
};

export const FIELD_TYPES = {
  TEXT: 'TEXT',
  EDITOR: 'EDITOR',
  IMAGE: 'IMAGE',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  INTEGER: 'INTEGER',
  NUMERIC: 'NUMERIC',
  CURRENCY: 'CURRENCY',
  NUMBER: 'NUMBER',
  OPTION: 'OPTION',
  LINK: 'LINK',
  COLOR: 'COLOR',
  PERCENT: 'PERCENT',
  PASSWORD: 'PASSWORD',
  CHECKBOX: 'CHECK BOX',
  RADIOBOX: 'RADIO BOX',
  CODE_EDITOR: 'CODE EDITOR',
  TAGS: 'TAGS',
  MATRIX: 'MATRIX',
};

export const ENTITY = {
  SYSTEM_CONFIGURATIONS: 'SYSTEM_CONFIGURATIONS',
};

export const CATEGORY_TYPE = {
  SERVICE: 'SERVICE',
  ARTICLE: 'ARTICLE',
};

export const PLACEMENT = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  BOTTOM: 'BOTTOM',
};
