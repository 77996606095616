// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { ROLES } from '../../../constants';
import SettingsIcon from '@mui/icons-material/Settings';
import BugReportTwoToneIcon from '@mui/icons-material/BugReportTwoTone'; // ----------------------------------------------------------------------
import GroupsIcon from '@mui/icons-material/Groups';
import CategoryIcon from '@mui/icons-material/Category';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CollectionsIcon from '@mui/icons-material/Collections';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const sidebarConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    roles: [ROLES.ADMIN],
    items: [
      {
        title: 'category',
        path: '/dashboard/categories',
        icon: <CategoryIcon />,
      },
      {
        title: 'configuration',
        path: '/dashboard/system-configurations',
        icon: <SettingsIcon />,
      },
      {
        title: 'team',
        path: '/dashboard/teams',
        icon: <GroupsIcon />,
      },

      {
        title: 'service',
        path: '/dashboard/services',
        icon: <MiscellaneousServicesIcon />,
      },
    ],
  },
  {
    subheader: 'editor',
    roles: [ROLES.EDITOR],
    items: [
      {
        title: 'post',
        path: '/dashboard/posts',
        icon: <NewspaperIcon />,
      },
      {
        title: 'gallery',
        path: '/dashboard/albums',
        icon: <CollectionsIcon />,
      },
    ],
  },
  {
    subheader: 'release notes',
    items: [
      {
        title: '1.0.0',
        path: '/dashboard/1.0.0',
        icon: <NewReleasesIcon />,
      },
      {
        title: '1.0.1',
        path: '/dashboard/1.0.1',
        icon: <NewReleasesIcon />,
      },
    ],
  },

  //TESTING
  {
    subheader: 'testing',
    roles: [ROLES.ADMIN],
    items: [
      {
        title: 'test form',
        path: '/dashboard/test/form',
        icon: <BugReportTwoToneIcon />,
      },
    ],
  },
];

export default sidebarConfig;
