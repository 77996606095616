import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import DashboardLayout from "../layouts/dashboard";
import ProtectedRoute from "../layouts/protected-route";
import ContainerLayout from "../layouts/container";

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<ContainerLayout />}>
        <Route element={<Navigate to="home" replace />} index />
        <Route
          path="dashboard"
          element={
            <ProtectedRoute>
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          <Route path="home" element={<HomePage />} />
          <Route path="system-configurations">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<SettingListPage />} />
            <Route path="form/:id" element={<SettingFormPage />} />
          </Route>
          <Route path="teams">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<TeamListPage />} />
            <Route path="form/:id" element={<TeamFormPage />} />
          </Route>
          <Route path="categories">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<CategoryListPage />} />
            <Route path="form/:id" element={<CategoryFormPage />} />
          </Route>
          <Route path="services">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<ServiceListPage />} />
            <Route path="form/:id" element={<ServiceFormPage />} />
          </Route>
          <Route path="posts">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<PostListPage />} />
            <Route path="form/:id" element={<PostFormPage />} />
          </Route>
          <Route path="albums">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<GalleryListPage />} />
            <Route path="form/:id" element={<GalleryFormPage />} />
          </Route>
          <Route path="1.0.0" element={<Version100Page />} />
          <Route path="1.0.1" element={<Version101Page />} />
        </Route>
        <Route path="about">
          <Route index element={<AboutPage />} />
        </Route>
        <Route path="user">
          <Route path={`login`} element={<LoginPage />} />
          <Route path={`change-password`} element={<ChangePasswordPage />} />
        </Route>
        <Route path="home">
          <Route index element={<Home />} />
        </Route>
        <Route path="teams">
          <Route path="detail/:slug-:id" element={<Team />} />
        </Route>
        <Route path="services">
          <Route path="detail/:slug-:id" element={<Service />} />
        </Route>
        <Route path="articles">
          <Route path="list" element={<ArticleList />} />
          <Route path="detail/:slug-:id" element={<ArticleDetail />} />
        </Route>
        <Route path="galleries">
          <Route path="detail/:slug-:id" element={<Gallery />} />
        </Route>
        <Route path="contact">
          <Route index element={<Contact />} />
        </Route>
        <Route path="contact-consulting">
          <Route index element={<ContactConsulting />} />
        </Route>
      </Route>
    </Routes>
  );
}

const HomePage = Loadable(lazy(() => import("../pages/home")));
const Version100Page = Loadable(lazy(() => import("../pages/release/1.0.0")));
const Version101Page = Loadable(lazy(() => import("../pages/release/1.0.1")));

const PostListPage = Loadable(lazy(() => import("../pages/admin/post/list")));
const PostFormPage = Loadable(lazy(() => import("../pages/admin/post/form")));

const GalleryListPage = Loadable(
  lazy(() => import("../pages/admin/gallery/list"))
);
const GalleryFormPage = Loadable(
  lazy(() => import("../pages/admin/gallery/form"))
);

const ServiceListPage = Loadable(
  lazy(() => import("../pages/admin/service/list"))
);
const ServiceFormPage = Loadable(
  lazy(() => import("../pages/admin/service/form"))
);

const CategoryListPage = Loadable(
  lazy(() => import("../pages/admin/category/list"))
);
const CategoryFormPage = Loadable(
  lazy(() => import("../pages/admin/category/form"))
);

const TeamListPage = Loadable(lazy(() => import("../pages/admin/team/list")));
const TeamFormPage = Loadable(lazy(() => import("../pages/admin/team/form")));

const SettingListPage = Loadable(
  lazy(() => import("../pages/admin/setting/list"))
);
const SettingFormPage = Loadable(
  lazy(() => import("../pages/admin/setting/form"))
);

const AboutPage = Loadable(lazy(() => import("../pages/about")));
const LoginPage = Loadable(lazy(() => import("../pages/user/Login")));
const ChangePasswordPage = Loadable(
  lazy(() => import("../pages/user/ChangePassword"))
);

const Home = Loadable(lazy(() => import("../pages/guest/home")));
const Team = Loadable(lazy(() => import("../pages/guest/team")));
const Service = Loadable(lazy(() => import("../pages/guest/service")));
const ArticleList = Loadable(lazy(() => import("../pages/guest/article/list")));
const ArticleDetail = Loadable(
  lazy(() => import("../pages/guest/article/detail"))
);

const Gallery = Loadable(lazy(() => import("../pages/guest/gallery")));
const Contact = Loadable(lazy(() => import("../pages/guest/contact")));
const ContactConsulting = Loadable(
  lazy(() => import("../pages/guest/contact-consulting"))
);
