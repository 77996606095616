import { isNil, set } from 'lodash';
import { get, groupBy } from 'lodash';
import { TYPES } from './actions';

export const initialState = {
  user: {},
  configs: {},
  menu: [],
  background: null,
  open: true,
  currentNode: null,
  latestNode: null,
  showSetting: false,
  menuPlacement: 'LEFT',
  fetching: false,
};

const reducer = (state, { type, ...args }) => {
  switch (type) {
    case TYPES.GET_USER: {
      const { user } = args;
      return {
        ...state,
        user,
      };
    }

    case TYPES.LOGOUT_USER: {
      return {
        ...state,
        ...initialState,
      };
    }

    case TYPES.TOGGLE_MENU: {
      return { ...state, open: !state.open };
    }

    case TYPES.FETCHING_MENU: {
      const { fetching } = args;
      return { ...state, fetching };
    }

    case TYPES.OPEN_MENU: {
      const { open } = args;
      return { ...state, open };
    }

    case TYPES.SET_BACKGROUND: {
      const { background } = args;
      return {
        ...state,
        background,
      };
    }

    case TYPES.GET_CONFIGS: {
      const { configs } = args;
      return {
        ...state,
        configs: configs.reduce((pre, item) => {
          let value = get(item, 'configuration.value');
          try {
            value = JSON.parse(value);
          } catch (err) {}

          return {
            ...pre,
            [item.name]: { ...item, value },
          };
        }, {}),
      };
    }

    case TYPES.BUILD_MENU: {
      const { menu } = args;
      return {
        ...state,
        menu,
      };
    }

    case TYPES.SHOW_SETTING: {
      const { showSetting } = args;
      return {
        ...state,
        showSetting,
      };
    }

    case TYPES.SAVE_SETTING: {
      localStorage.setItem('menuPlacement', state.menuPlacement);
      return {
        ...state,
        showSetting: false,
      };
    }

    case TYPES.CHANGE_PLACEMENT: {
      const { menuPlacement } = args;
      return {
        ...state,
        menuPlacement,
      };
    }

    case TYPES.UPDATE_MENU: {
      const { children, key, hasPaging, count, page } = args;
      const [k, index] = key.split('.');
      const newMenu = [...state.menu];

      const target = newMenu.find((ele) => ele.key === k);
      if (target) {
        if (hasPaging) {
          target.hasPaging = hasPaging;
          target.count = count;
          target.page = page || 0;
        }
        if (children?.length > 0) {
          if (isNil(index)) {
            target.children = children;
          } else {
            if (target.children[index]) {
              target.children[index].children = children;
            }
          }
        }
      }

      return { ...state, menu: newMenu, fetching: false };
    }

    case TYPES.SET_LATEST_NODE: {
      const { latestNode } = args;
      return {
        ...state,
        latestNode,
      };
    }

    case TYPES.SET_CURRENT_NODE: {
      const { currentNode } = args;
      return {
        ...state,
        currentNode,
      };
    }

    default:
      return state;
  }
};

export default reducer;
