import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { withDashboardContextProvider, useDashboardContext } from './context';
import { HOME_PAGE } from '../../constants';

import style from './style.module.scss';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const ContainerLayout = () => {
  const { onGetConfigs } = useDashboardContext();
  useEffect(() => {
    onGetConfigs();
  }, []);
  return (
    <div className={style.page}>
      <Outlet />
    </div>
  );
};

export default withDashboardContextProvider(ContainerLayout);
