import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Typography, Avatar } from '@mui/material';
import classNames from 'classnames';

import { useDashboardContext } from '../../../layouts/container/context';
import { getFullName } from '../../../utils';

import style from './style.module.scss';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { user, onLogoutUser } = useDashboardContext();
  return (
    user.login && (
      <Link underline="none" color="inherit">
        <RootStyle
          sx={{
            ...(isCollapse && {
              bgcolor: 'transparent',
            }),
          }}
        >
          <div className={style.nameWrapper}>
            <Avatar alt={`${getFullName(user)}`} className={style.avatar} />
            <div
              className={classNames(style.label, {
                [style.hidden]: isCollapse,
              })}
            >
              <Typography variant="subtitle2" noWrap>
                {`${getFullName(user)}`}
              </Typography>
              <Typography
                variant="body2"
                noWrap
                sx={{ color: 'text.secondary' }}
              >
                {`${user.authorities.join(', ')}`}
              </Typography>
            </div>
          </div>
        </RootStyle>
      </Link>
    )
  );
}
