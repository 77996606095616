import Humps from 'humps';
import axios from 'axios';
import { omit, isNumber, isEmpty, get, isArray } from 'lodash';
import queryString from 'query-string';

import handleError from './handle-error';

export const makeHttpClient = ({
  baseUrl,
  csrfToken,
  withCredentials = false,
}) => {
  const client = axios.create({
    baseURL: baseUrl,
    withCredentials,
    transformResponse: (data) =>
      data ? Humps.camelizeKeys(JSON.parse(data)) : {},
    transformRequest: [JSON.stringify],
    paramsSerializer: (params) =>
      queryString.stringify(
        omit(params, (value) => {
          if (isNumber(value)) {
            return false;
          }
          return isEmpty(value);
        }),
        {
          arrayFormat: 'repeat',
        }
      ),
  });

  client.interceptors.request.use(
    async (config) => {
      const idToken = window.localStorage.id_token || '';
      const authorization = `Bearer ${idToken}`;
      config.headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
        Authorization: authorization,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  const extractor = (res) => {
    const { data, headers } = res;
    const totalCount = get(headers, 'x-total-count');
    const contentType = get(headers, 'content-type');
    if (contentType?.includes('image/')) {
      return { data, contentType };
    }
    return isArray(data) ? { items: data, totalCount, contentType } : data;
  };

  const getJSON = (url, data = {}, options = {}, useInlineMessage) => {
    return handleError(
      client.get(url, {
        ...options,
        params: data,
      }),
      useInlineMessage
    ).then(extractor);
  };

  const patchJSON = (url, data = {}, options = {}, useInlineMessage) =>
    handleError(
      client.patch(url, {
        ...options,
        params: data,
      }),
      useInlineMessage
    ).then(extractor);

  const postJSON = (url, data = {}, options = {}, useInlineMessage) =>
    handleError(client.post(url, data, options), useInlineMessage).then(
      extractor
    );
  const putJSON = (url, data = {}, options = {}, useInlineMessage) =>
    handleError(client.put(url, data, options), useInlineMessage).then(
      extractor
    );
  const deleteJSON = (url, options = {}, useInlineMessage) =>
    handleError(client.delete(url, options), useInlineMessage).then(extractor);

  return {
    getJSON,
    patchJSON,
    postJSON,
    putJSON,
    deleteJSON,
  };
};
